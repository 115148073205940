ion-slides {
  height: 100%;
}

.b {
  font-weight: bold;
}

.f10 {
  font-size: 10px;
}

.f12 {
  font-size: 12px;
}

.f13 {
  font-size: 13px;
}

.f14 {
  font-size: 14px;
}

.f15 {
  font-size: 15px;
}

.f16 {
  font-size: 16px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.f24 {
  font-size: 24px;
}

.f28 {
  font-size: 28px;
}

.f36 {
  font-size: 36px;
}

.f40 {
  font-size: 40px;
}

.f20xs {
  font-size: 16px;
}

.cursive {
  font-family: cursive;
}

@media (min-width: 400px) {
  .f20xs {
    font-size: 20px;
  }
}

.ptb3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.c-light {
  color: #f4f5f8;
}

.op5 {
  opacity: .5;
}

.w20 {
  min-width: 20px;
  display: inline-block;
}

.cursorP {
  cursor: pointer;
}

.debug {
  white-space: pre-wrap;
  font-size: 12px;
}

.flag {
  width: 40px;
  height: 30px;
  margin: 2px 0;
  box-shadow: 0px 0px 2px #888;
}

.flag-small {
  width: 20px;
  height: 15px;
  margin: 2px 0;
  box-shadow: 0px 0px 2px #888;
}

.flag-big-owner {
  width: 40px;
  height: 30px;
  margin: 0 10px 0 3px;
  box-shadow: 0px 0px 2px #888;
}

.center {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.message-box {
  margin: 7px;
  padding: 6px;
  background-color: var(--ion-color-warning-tint);
  border: 2px solid var(--ion-color-warning-shade);
  border-radius: 8px;
  color: #222428;
}

.vertical-center {
  display: flex;
  align-items: center;
}

@media only screen and (min-height: 600px) and (min-width: 768px) {
  .sc-ion-modal-md-h {
    --height: 600px;
  }
}

@media only screen and (min-height: 640px) and (min-width: 768px) {
  .sc-ion-modal-md-h {
    --height: 640px;
  }
}

@media only screen and (min-height: 720px) and (min-width: 768px) {
  .sc-ion-modal-md-h {
    --height: 720px;
  }
}

@media only screen and (min-height: 780px) and (min-width: 768px) {
  .sc-ion-modal-md-h {
    --height: 780px;
  }
}

@media only screen and (min-height: 840px) and (min-width: 768px) {
  .sc-ion-modal-md-h {
    --height: 840px;
  }
}

/*
@media(pointer: fine) {
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        background: #fff;
    }
    ::-webkit-scrollbar-track:hover {
        background: #f7f7f7;
    }
    ::-webkit-scrollbar-thumb {
        background: #ccc;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #888
    }
    .inner-scroll {
        scrollbar-width: thin
    }
}
*/

.required:after {
  content: " *";
  color: red;
}

/*
body::-webkit-scrollbar {
    width: 5px !important;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    border-radius: 10px !important;
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey !important;
    outline: 1px solid slategrey !important;
}

::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
border-radius: 10px!important;
background-color: #F5F5F5!important;
}

::-webkit-scrollbar
{
width: 10px!important;
background-color: #F5F5F5!important;
}

::-webkit-scrollbar-thumb
{
border-radius: 10px!important;
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)!important;
background-color: #D62929!important;
}
*/

/* Hide spinn button on number inputs */
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}