@media only screen and (min-height: 600px) and (min-width: 768px) {
    .modal-default {
        --height: 600px;
    }
}

@media only screen and (min-height: 640px) and (min-width: 768px) {
    .modal-default {
        --height: 640px;
    }
}

@media only screen and (min-height: 720px) and (min-width: 768px) {
    .modal-default {
        --height: 720px;
    }
}

@media only screen and (min-height: 780px) and (min-width: 768px) {
    .modal-default {
        --height: 780px;
    }
}

@media only screen and (min-height: 840px) and (min-width: 768px) {
    .modal-default {
        --height: 840px;
    }
}